import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function NativeSelects(props) {
  const classes = useStyles();
  let options = [];
  for (let i = 0; i < props.options.length; i++) {
    options.push(
      <>
        <option value={[props.options[i]]}>{props.options[i]}</option>
      </>
    );
  }
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="outlined-age-native-simple">
        {props.title}
      </InputLabel>
      <Select
        native
        value={props.value}
        onChange={props.handleChange}
        label={props.title}
        name={props.name}
      >
        {options}
      </Select>
    </FormControl>
  );
}
