import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/utils/layout"
import Loader from "../components/utils/Loader"
import SEO from "../components/utils/seo"
import TitleLine from "../components/utils/TitleLine"
import createTeacherAccount from "../scripts/createTeacherAccount"
import VerifyEmail from "../components/onboarding/VerifyEmail"
import "../styles/SignUpPage.css"
import InputCreator from "../components/styledInputs/InputCreator.js"
import Dropdown from "../components/styledInputs/Dropdown.js"
import { auth } from "../firebase"
import NewAsyncButton from "../components/utils/NewAsyncButton"
import dots from "../images/bgs/dots.svg"

class TeacherAccount extends React.Component {
  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true })
      }
    })
  }
  constructor(props) {
    super(props)
    this.state = {
      grade: "Kindergarten",
      school: "",
      PreferredPrefix: "Ms",
      ConfirmPassword: "",
      firebase: null,
      authenticated: false,
      clicked: false,
      verifyEmail: false,
      seriesOne: {},
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.signUpTeacher = this.signUpTeacher.bind(this)
    this.createAccount = this.createAccount.bind(this)
    this.setAddress = this.setAddress.bind(this)
  }

  createAccount(reset) {
    const {
      FirstName,
      LastName,
      Email,
      school,
      Password,
    } = this.state.seriesOne
    let userData = {
      firstName: FirstName,
      lastName: LastName,
      email: Email,
      userType: "teacher",
      school: school,
      grade: this.state.grade,
      pendingProjects: [],
      activeProjects: [],
      reviewProjects: [],
      preferredPrefix: this.state.PreferredPrefix,
    }
    createTeacherAccount(Email, Password, userData)
      .then(() => {
        console.log("then")
        reset("Successfully Registered!", 3)
        if (typeof window !== "undefined") {
          if (window.fbq != null) {
            window.fbq("track", `Teacher Registration Start`, {
              uid: auth.currentUser.uid,
            })

            window.fbq("trackCustom", "Teacher Registration Start Custom", {
              uid: auth.currentUser.uid,
            })
          }
        }
        this.setState({
          verifyEmail: true,
        })
      })
      .catch(error => {
        console.log(error) //TODO Custom error messages for async buttons
        if (error.code === "auth/email-already-in-use") {
          reset("This email is already in use", 2)
          //alert("This email is already in use")
        } else if ((error.code === "auth/invalid-email", 2)) {
          reset("Please enter a valid email address")
          //alert("Please enter a valid Email", 2)
        } else if ((error.code === "auth/weak-password", 2)) {
          reset("Please make sure your password is longer than 6 characters")
          // alert("Make sure your password is longer than 6 characters", 2)
        } else {
          reset("Error Creating Account. Try Again?", 2)
        }
      })
  }

  signUpTeacher(reset) {
    const {
      FirstName,
      LastName,
      Email,
      school,
      Password,
      ConfirmPassword,
    } = this.state.seriesOne
    if (
      Object.keys(this.state.seriesOne).length === 7 &&
      FirstName !== "" &&
      LastName !== "" &&
      Email !== "" &&
      school !== ""
    ) {
      if (Password === ConfirmPassword && Password.length >= 6) {
        this.createAccount(reset)
      } else {
        reset("Passwords do not match", 3)
        alert("Passwords do not match")
      }
    } else {
      reset("Please fill out all input fields", 3)
      alert("Please fill out all input fields")
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleForm = event => {
    let tmp = this.state.seriesOne
    tmp[event.target.name] = event.target.value
    this.setState({ seriesOne: tmp })
  }

  setAddress(val) {
    let newseries = this.state.seriesOne
    newseries.school = val.terms[0].value
    newseries.placeID = val.place_id
    if (val) {
      this.setState({
        seriesOne: newseries,
      })
    }
  }

  render() {
    const regularInput = [
      "First Name",
      "Last Name",
      "School",
      "School Email",
      "Password",
      "Confirm Password",
    ]
    const inputTypes = [
      "text",
      "text",
      "school",
      "text",
      "password",
      "password",
    ]
    const inputNames = [
      "FirstName",
      "LastName",
      "school",
      "Email",
      "Password",
      "ConfirmPassword",
    ]

    const grades = [
      "Kindergarten",
      "1st",
      "2nd",
      "3rd",
      "4th",
      "5th",
      "6th",
      "7th",
      "8th",
      "9th",
      "10th",
      "11th",
      "12th",
    ]

    const prefixes = ["Ms", "Mrs", "Mr"]

    if (!this.state.creating) {
      if (!this.state.verifyEmail) {
        return (
          <Container className="topSpacing">
            <TitleLine
              title="Create Teacher Account"
              description="Create your account to begin posting tasks and saving time"
            />
            <Row className="justify-content-center">
              <InputCreator
                titles={regularInput}
                errors={[false, false, false, false, false, false]}
                types={inputTypes}
                names={inputNames}
                struct={[2, 2, 1, 1]}
                callback={this.handleForm}
                values={this.state.seriesOne}
                schoolCallback={this.setAddress}
              />
            </Row>
            <Row className="justify-content-center">
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Dropdown
                  value={this.state.grade}
                  handleChange={this.handleInputChange}
                  options={grades}
                  name="grade"
                  title="Grade Taught"
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Dropdown
                  value={this.state.PreferredPrefix}
                  handleChange={this.handleInputChange}
                  options={prefixes}
                  name="PreferredPrefix"
                  title="Prefix"
                />
              </Col>
            </Row>
            <Row
              className=" justify-content-center sidesPadding center"
              style={{ marginTop: "35px" }}
            >
              <NewAsyncButton
                buttonTexts={[
                  "Register",
                  "Creating Account...",
                  "Error Creating Account. Try Again?",
                  "Successfully Registered!",
                ]}
                buttonVariants={["primary", "primary", "danger", "success"]}
                buttonColor={["", "", "", ""]}
                callback={this.signUpTeacher}
              />
            </Row>
          </Container>
        )
      } else {
        return (
          <VerifyEmail
            email={this.state.seriesOne.Email}
            password={this.state.seriesOne.Password}
            navigate="/teacherCongrats/"
          />
        )
      }
    } else {
      return <Loader />
    }
  }
}
const CreateTeacherAccountPage = () => (
  <Layout image={dots} full>
    <SEO title="Create Teacher Account" />
    <TeacherAccount />
  </Layout>
)

export default CreateTeacherAccountPage
