import React from "react"
import { Container, Row, Spinner } from "react-bootstrap"
import "../../styles/Loader.css"

function Loader() {
  return (
    <Container
      className="justify-content-center"
      style={{ marginTop: "300px" }}
    >
      <Row className="justify-content-center">
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="info" />
      </Row>
    </Container>
  )
}
export default Loader
